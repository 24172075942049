@import '../styles/breakpoints.scss';

.custom-bullet {
  list-style-type: none; // Remove default bullets
}

.custom-bullet li::before {
  content: '•';
  position: absolute;
  margin-left: -1rem;
  font-size: 1.5rem;
  vertical-align: top;

  @media (min-width: $md) {
    margin-left: -1.25rem;
    font-size: 2rem;
    vertical-align: top;
  }
}

.custom-bullet li {
  position: relative;
  margin-bottom: 28px; // Spacing between list items
}

.custom-bullet li:last-of-type {
  margin-bottom: 0px;
}

.privacy-policy .custom-bullet li {
  margin-bottom: 18px; // Spacing between list items
}
