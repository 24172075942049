@import 'breakpoints';

.abio-leadspace-title {
  font-family: 'Red Hat Display';
  font-weight: 400;
  font-size: 38px;
  line-height: 1.2;
  margin-bottom: 1.5rem;

  @media (min-width: $md) {
    font-size: 50px;
    line-height: 123%;
    margin-bottom: 2.5rem;
  }
}

.abio-feature-title {
  font-family: 'Red Hat Display';
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 1.5rem;

  @media (min-width: $md) {
    font-size: 38px;
    line-height: 140%;
    margin-bottom: 2.5rem;
  }
}

.abio-title {
  font-family: 'Red Hat Display';
  font-size: 24px;
  line-height: 150%;
  font-weight: 400;

  @media (min-width: $md) {
    font-size: 28px;
    line-height: 150%;
  }
}

.abio-contact-paragraph {
  font-family: 'Red Hat Text';
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
}

.abio-form-label {
  font-family: 'Red Hat Display';
  font-size: 20px;
  line-height: 130%;
  font-weight: 400;
  margin-top: 0.75rem;
  margin-bottom: 3rem;
}

.abio-paragraph {
  font-family: 'Red Hat Text';
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;

  @media (min-width: $md) {
    font-size: 18px;
    line-height: 150%;
  }
}

.abio-footer-item {
  font-family: 'Red Hat Text';
  font-size: 18px;
  line-height: 180%;
  font-weight: 400;
}

.abio-link {
  font-family: 'Red Hat Display';
  font-size: 18px;
  line-height: 133%;
  font-weight: 700;
}

.abio-button {
  font-family: 'Red Hat Display';
  font-size: 18px;
  line-height: 24px;
}

.abio-sup-title {
  font-family: 'Red Hat Display';
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0.75rem;

  @media (min-width: $md) {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 1rem;
  }
}
