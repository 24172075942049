@import '../styles/breakpoints';

.feature-card {
  &__title {
    padding: 2rem 1.25rem 1rem 1.25rem;

    @media (min-width: $sm) {
      padding: 1.5rem 1.25rem 0.75rem 1.25rem;
    }

    @media (min-width: $lg) {
      padding: 2.375rem 2.5rem 2rem 2.5rem;
    }
  }

  &__description {
    padding: 0 1.25rem 1.25rem 1.25rem;

    @media (min-width: $sm) {
      padding: 0 1.25rem 1rem 1.25rem;
    }

    @media (min-width: $lg) {
      padding: 0 2.5rem 2.75rem 2.5rem;
    }
  }

  &__link {
    // override abio-link-container in spacingStyles.scss
    .abio-link-container {
      position: absolute;
      z-index: 1;
      left: 1.25rem;
      bottom: 1.875rem;
      margin-top: 1.5rem;

      @media (min-width: $sm) {
        bottom: 1.5rem;
        margin-top: 2rem;
      }

      @media (min-width: $lg) {
        left: 2.5rem;
        bottom: 3rem;
        margin-top: 4.75rem;
      }
    }
  }
}
