@import '../styles/breakpoints';

.abio-carousel {
  overflow: hidden;

  // Flickity viewport
  .flickity-viewport {
    overflow: visible;
    margin-bottom: 48px;
  }

  // Flickity buttons
  .flickity-button {
    background-color: #0c4278;
    color: #ffffff;

    &:hover {
      background-color: #ff9933;
      filter: drop-shadow(0 0 8px rgba(255, 153, 51, 0.25));
    }

    .flickity-button-icon {
      position: absolute;
      top: 30%;
      left: 30%;
      width: 40%;
      height: 40%;
    }
  }

  // Prev & Next buttons
  .flickity-prev-next-button {
    &.next {
      right: -8%;
    }

    &.previous {
      left: -8%;
    }
  }

  // Page Dots
  .flickity-page-dots {
    width: auto;
    right: 0;
    bottom: auto;

    .dot {
      background: #fff;
      margin: 0 10px;

      @media (min-width: $sm) {
        margin: 0 12px;
      }

      &.is-selected {
        transform: scale(1.33333);
      }
    }
  }
}
