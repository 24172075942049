@import 'breakpoints';

@mixin responsive-margins($top, $bottom, $md-top: $top, $md-bottom: $bottom) {
  margin-top: $top;
  margin-bottom: $bottom;

  @media (min-width: $md) {
    margin-top: $md-top;
    margin-bottom: $md-bottom;
  }
}

@mixin responsive-padding($top, $bottom, $md-top: $top, $md-bottom: $bottom) {
  padding-top: $top;
  padding-bottom: $bottom;

  @media (min-width: $md) {
    padding-top: $md-top;
    padding-bottom: $md-bottom;
  }
}

.leadspace-container {
  margin-bottom: 5rem;
}

.leadspace-text-container {
  @include responsive-margins(4rem, 4rem);
}

.abio-link-container {
  margin-top: 2.5rem;
}

.feature-container,
.callout-container,
.inline-callout-container,
.inline-callout-right-container,
.inline-callout-2-container,
.icon-list-container {
  @include responsive-margins(3rem, 5rem, 9rem, 9rem);
}

.feature-container img.feature-image,
.inline-callout-right-container img,
.inline-callout-2-container img {
  margin-top: 3rem;

  @media (min-width: $md) {
    margin-top: 0;
  }
}

.callout-container img {
  margin-bottom: 2rem;

  @media (min-width: $md) {
    margin-bottom: 0;
  }
}

.callout-container .abio-title,
.callout-container .abio-feature-title {
  @include responsive-margins(3rem, 3rem, 9rem, 9rem);
}

.inline-callout-container .abio-title,
.inline-callout-container .abio-feature-title {
  @include responsive-margins(3rem, 3rem, 0, 0);
}

.inline-callout-right-container .abio-title,
.inline-callout-right-container .abio-feature-title,
.inline-callout-2-container .abio-title,
.inline-callout-2-container .abio-feature-title {
  // remove margin on text
  margin-top: 0;
  margin-bottom: 0;
}

.carousel-container {
  @include responsive-padding(3rem, 4rem, 5rem, 5rem);
}

.carousel-container .carousel-title-spacer {
  margin-bottom: 3rem;

  @media (min-width: $md) {
    margin-bottom: 5rem;
  }
}

.contact-form-text-container {
  padding-top: 3rem;
  margin-bottom: -10%;

  @media (min-width: $sm) {
    margin-bottom: -20%;
  }

  @media (min-width: $md) {
    padding-top: 5.25rem;
    margin-bottom: -25%;
  }
}

.icon-list-container ul.list-2 {
  margin-top: 2rem;

  @media (min-width: $md) {
    margin-top: 2.5rem;
    margin-bottom: 3.5rem;
  }
}

.bottom-nav-container {
  @include responsive-padding(3rem, 3rem, 4rem, 4rem);
}
